
services = angular.module("questionnaireApp.services", [])

services.factory 'LoginRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getLoginService: (id) ->
            ref = firebase.database().ref('/Login/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}
]


services.factory 'QuestionnaireRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getQuestionnaireInfoService: (id) ->
            ref = firebase.database().ref('/Surveys/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}

        getQuestionnaireItemsService: (id) ->
            ref = firebase.database().ref('/SurveyItems/'+ id + '/')
            obj = $firebaseArray(ref.orderByChild('position'))
            return {obj:obj}

        getQuestionnaireSegmentsService: (id) ->
            ref = firebase.database().ref('/SurveySegments/'+ id + '/')
            obj = $firebaseArray(ref)
            return {obj:obj}

        getQuestionnaireLabelsService: (id) ->
            ref = firebase.database().ref('/SurveyLabels/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}

        getQuestionnaireAnonymousLimitService: (id) ->
            ref = firebase.database().ref('/SurveyLimit/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}
]



services.factory 'QuestionnairePreviewRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getQuestionnaireInfoService: (id) ->
            ref = manage.database().ref('/Surveys/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}

        getQuestionnaireItemsService: (id) ->
            ref = manage.database().ref('/SurveyItems/'+ id + '/')
            obj = $firebaseArray(ref.orderByChild('position'))
            return {obj:obj}

        getQuestionnaireSegmentsService: (id) ->
            ref = manage.database().ref('/SurveySegments/'+ id + '/')
            obj = $firebaseArray(ref)
            return {obj:obj}

        getQuestionnaireLabelsService: (id) ->
            ref = manage.database().ref('/SurveyLabels/'+ id + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}
]

services.factory 'BlacklistRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getBlacklistServiceForRespondent: (id, respondent) ->
            ref = manage.database().ref('/Blacklist/'+ id + '/' + respondent + '/')
            obj = $firebaseObject(ref)
            return {ref:ref}
]


services.factory 'RespondentRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getRespondentResponsesService: (survey, respondent) ->
            ref = firebase.database().ref('/Responses/'+ survey + '/' + respondent + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}

        getRespondentService: (survey, respondent) ->
            ref = firebase.database().ref('/Respondents/'+ survey + '/' + respondent + '/')
            obj = $firebaseObject(ref)
            return {obj:obj}
]


services.factory 'ConnectionRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getConnectionService: (survey, respondent) ->
            ref = firebase.database().ref('.info/connected')
            return {ref:ref}
]


services.factory 'FeedbackRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getFeedbackService: ->
            ref = firebase.database().ref('/Feedback/')
            obj = $firebaseArray(ref)
            return {obj:obj}
]
